<template>

<div :class="windowClass">
   <v-container>
      <v-card flat>
          <v-toolbar class="mid-font" color="primary" dense dark >
              <v-icon left >mdi-account-box</v-icon><span class="va-underline"> Data</span>
              <v-spacer/>
                <v-btn small @click="redirect(0)" color="warning">New </v-btn>
            </v-toolbar>
          <v-card-text>
              <v-row v-if="isMember==true" no-gutters>
                Member under <span class="va-underline ml-3 font-weight-bold">{{parent.first_name}}, {{parent.surname}}</span>
                <v-spacer/>
                <v-btn x-small @click="datatable=true"><v-icon left small>mdi-magnify</v-icon>Searh from data</v-btn>
              </v-row>
               <v-form
                  ref="form"
                  class="mt-4"
                  v-model="valid"
                  lazy-validation
                >
                <v-row>
                  <v-col cols="12" sm="12">
                    <div v-if="view" class="text-center">
                        <img v-if="item.image" :src="item.image" @click="view=false,toggleCamera"/>
                        <v-btn v-else type="button" small text color="accent" :class="{ 'is-primary' : !isCameraOpen, 'is-danger' : isCameraOpen}" @click="view=false,toggleCamera">
                                <span v-if="!isCameraOpen"><v-icon left>mdi-camera</v-icon>Open Camera</span>
                                <span v-else><v-icon left>mdi-camera-off-outline</v-icon> Close Camera</span>
                            </v-btn>
                    </div>
                    <div class="web-camera-container text-center"  v-else>
                          <div class="camera-button">
                              <v-btn type="button" small text color="accent" :class="{ 'is-primary' : !isCameraOpen, 'is-danger' : isCameraOpen}" @click="toggleCamera">
                                <span v-if="!isCameraOpen"><v-icon left>mdi-camera</v-icon>Open Camera</span>
                                <span v-else><v-icon left>mdi-camera-off-outline</v-icon> Close Camera</span>
                            </v-btn>
                          </div>
                          <div class="pa-1"></div>
                          <div v-show="isCameraOpen && isLoading" class="camera-loading text-center">
                                                          <v-progress-circular
                                    indeterminate
                                    color="primary"
                                  ></v-progress-circular>
                          </div>
                          
                          <div v-if="isCameraOpen" v-show="!isLoading" class="camera-box" :class="{ 'flash' : isShotPhoto }">
                            
                            <div class="camera-shutter" :class="{'flash' : isShotPhoto}"></div>
                              
                            <video v-show="!isPhotoTaken" ref="camera" :width="320" :height="237.5" autoplay></video>
                            
                            <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas" :width="320" :height="237.5"></canvas>
                          </div>
                          
                          <div v-if="isCameraOpen && !isLoading" class="camera-shoot">
                            <button type="button" class="button" @click="takePhoto">
                              <img src="https://img.icons8.com/material-outlined/50/000000/camera--v2.png">
                            </button>
                          </div>
                        
                          <div v-if="isPhotoTaken && isCameraOpen" class="camera-download">
                            <v-btn small text color="success"  @click="saveImage"><v-icon small left v-if="isImage">mdi-check</v-icon>Use this Picture</v-btn>
                          </div>
                        </div>
                   </v-col>
                   <v-col cols="12" sm="12">
                   <template v-if="item.id">
                    <p v-if="item.position=='Sibling' || item.position=='Wife'">
                      Head of the family: {{ headOfFamily }}
                      <v-autocomplete
                          v-if="headOfFamily == ''"
                          class="mt-2"
                          v-model="item.parent_id"
                          dense
                          item-value="id"
                          :item-text="getItemText"
                          label="Select"
                          :items="headOfFamilyList"
                        />
                  </p>
                  </template>
                   </v-col> 
                   <v-col cols="4" sm="4">
                        <v-select
                          v-model="item.position"

                          :rules="textRules"
                          dense
                          label="Position"
                          :items="['Head of the family', 'Wife', 'Sibling', 'Solo']"
                        />
                  </v-col>
                  <v-col cols="4" sm="4">
                    <v-select
                  v-model="item.gender"
                  :rules="textRules"
                  dense
                  label="Gender"
                  :items="['Male', 'Female']"
                />
                  </v-col>
                  <v-col cols="4" sm="4">
                    <v-select
                  v-model="item.civil_status"
                  :rules="textRules"
                  dense
                  label="Civil Status"
                  :items="['Married', 'Widow','Single']"
                />
                  </v-col>
                </v-row>
                <v-text-field  
                v-model="item.first_name"
                :rules="textRules"
                dense
                label="First Name"/>


                <v-text-field  
                v-model="item.surname"
                :rules="textRules"
                @change="validateRecord()"
                dense
                label="Surname"/>
               
               
                
                <v-text-field  
                v-model="item.middle_name"
                dense
                label="Middle Name"/>
                <v-card elevation="1" class="mb-4" v-if="dup_action=='check'">
                  <v-card-text class="pa-2">
                    <span class="font-weight-bold">{{duplicate.first_name}}, {{duplicate.surname}} </span> (Birth Date: 1985-09-24) already have record.
                  </v-card-text>
                  <v-card-actions>
                    <v-btn text color="info" x-small @click="LoadData()">Load Data</v-btn>

                    <v-btn text color="success" x-small @click="dup_action=''">Continue new data</v-btn>
                  </v-card-actions>
                </v-card>
                <v-row>
                
                  <v-col cols="6" sm="6">
                    <v-select
                  v-model="item.suffix"
                  dense
                  label="Suffix"
                  :items="$store.state.suffixes"
                />
                  </v-col>
                  <v-col cols="6" sm="6">
                    <span style="width: 200px; margin-top: 24px;"> 
                     <v-menu
                        v-model="date_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                                label="Birth Date"
                                 :value="item.birth_date"
                                 :rules="textRules"
                                 readonly
                                dense
                                
                                append-icon="mdi-calendar"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="item.birth_date" no-title scrollable @input="date_menu=false" />
                    </v-menu>
               </span>
                  </v-col>
                </v-row>

                <v-row>
                <v-col cols="6" sm="6">
                  <v-text-field  
                    v-model="item.contact_no"
                    dense
                    label="Contact No."/>
                </v-col>
                <v-col cols="6" sm="6">
                      <v-text-field  
                    v-model="item.email"
                    dense
                    label="Email"/>
                </v-col>
              </v-row>
               
  
             <v-row>
              <v-col cols="12" sm="6">
             
                  <v-autocomplete
                  v-model="item.region"
                  disabled
                  :rules="textRules"
                dense
                  label="Region "
                  :items="regions"
                        item-value="name"
                        item-text="name"
                        @change="setProvinces()"
                />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete
                dense
                v-model="item.province"
                :rules="textRules"
                  label="Province "
                  disabled
                  :items="provinces"
                  item-value="name"
                        item-text="name"
                        @change="setMunicipalities()"
                />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete
                  v-model="item.municipality"
                  disabled
                  :rules="textRules"
                dense
                  label="Municipality "
                  :items="municipalities"
                  @change="setBarangays()"
                />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete
                  v-model="item.barangay"
                  
                  :rules="textRules"
                dense
                  label="Barangay "
                  :items="barangays"
                />
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field  
                v-model="item.address"
                :rules="textRules"
                dense
                label="Line Address"/>
                </v-col>
             </v-row>
  
                <v-checkbox  
                dense
                hide-details
                value="yes"
                v-model="item.pwd"
                label="PWD"/>
  
                <v-checkbox  
                dense
                hide-details
                value="yes"
                v-model="item.senior"
                label="Senior Citizen"/>
  
                <v-checkbox  
                v-model="item.student"
                dense
                value="yes"
                hide-details
                label="Student"/>

                <v-checkbox  
                v-model="item.ph_member"
                dense
                value="yes"
                hide-details
                label="Phil Health Member"/>
                <v-checkbox  
                v-model="item.pppp_member"
                dense
                value="yes"
                hide-details
                label="4P`s Member"/>
  
              
                <v-row class="mt-2">
                
                <v-col cols="4" sm="4">
                  <v-autocomplete
                  :rules="textRules"
                    v-model="item.employment"
                    dense
                    :items="['N/A', 'Employed', 'Unemployed']"
                    label="Employment"/>
                </v-col>
                <v-col cols="8" sm="8">
                  <v-autocomplete
                
                     v-if="item.employment == 'Employed'"
                      v-model="item.occupation"
                      dense
                        label="Occupation "
                        :items="$store.state.occupations"
                      />
                </v-col>
               </v-row> 
                <v-row class="mt-2">
                
                <v-col cols="12" sm="12">
                  <v-text-field  
                    v-model="item.education"
                    dense
                    label="Education Attainment"/>
                </v-col>
                <v-col cols="12" sm="6">

                    <v-combobox
                    v-model="skills"
                    :rules="textRules"
                    dense
                    
                    label="Skills"
                    :items="$store.state.occupations"
                    clearable
  multiple
                  ></v-combobox>
                  
                </v-col>
                <v-col cols="12" sm="6">

                    <v-combobox
                    v-model="skills"
                    :rules="textRules"
                    dense

                    label="Other Skills"
                    :items="$store.state.occupations"
                    clearable
                    multiple
                    ></v-combobox>

                </v-col>

                <!-- <v-col cols="12" sm="12">
                      <v-text-field  
                      v-model="item.remarks"
                      dense
                      label="Remarks"/>
                </v-col> -->
              </v-row>


               </v-form>
          </v-card-text>
          <v-card-actions class="pa-4">
        
              <template v-if="!isMember">
                  <v-btn v-if="!item.id" x-small color="success" :loading="loading"  depressed  @click="validate()" >ADD </v-btn>
                  <v-btn v-if="item.id"  x-small color="success"  :loading="loading" depressed  @click="validate()" >update</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn v-if="item.id"  x-small color="info"  depressed  @click="newMember()" >Add MEMBER under {{item.first_name}}</v-btn>
              </template>
              <template v-else>
                  <v-btn v-if="!item.id"  x-small color="success"  :loading="loading" depressed  @click="validate()" >ADD {{item.first_name}} under {{parent.first_name}}</v-btn>
                  <v-btn v-if="item.id"  x-small color="success"  :loading="loading" depressed  @click="validate()" >update</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn v-if="item.id"  x-small color="info"  depressed  @click="newMember()" >Add more member </v-btn>
              </template>
          </v-card-actions>
      </v-card>
      <template v-if="members.length>0">
        <v-toolbar dense flat class="mt-4 mb-4"><v-spacer/>FAMILY TREE<v-spacer/></v-toolbar>
        
        <v-row justify="center">
          <v-col cols="12"  sm="3">
            
            <v-card class="pa-2 text-center" color="info" dark >
              <v-btn x-small text  @click="redirect(item.id)"><v-icon small>mdi-open-in-new</v-icon></v-btn>   {{item.surname}},  {{item.first_name}} ({{item.position}})
            </v-card>
          </v-col>
          <template v-for="(item, index) in memberPos('Wife')">
          <v-col cols="12"  sm="3" :key="index">
            <v-card class="pa-2  text-center"  color="info" dark>
              <v-btn x-small text @click="redirect(item.id)"><v-icon small>mdi-open-in-new</v-icon></v-btn>  {{item.surname}},  {{item.first_name}} ({{item.position}})
            </v-card>
            
          </v-col>
        </template> 
        </v-row>
        <v-divider class="mt-2 mb-2"></v-divider>
        <v-row justify="center">
           <template v-for="(item, index) in memberPos('Sibling')">
          <v-col cols="12"  sm="3" :key="index">
            <v-card class="pa-2  text-center">
              <v-btn x-small text color="success" @click="redirect(item.id)"><v-icon small>mdi-open-in-new</v-icon></v-btn>  {{item.surname}},  {{item.first_name}} ({{item.position}})
            </v-card>
          </v-col>
        </template> 
        </v-row>
        <div class="ma-8"></div>
<!-- 
        <v-data-table
                    class="va-table text-body-2"
                   
                    dense
                    :headers="headers"
                    :items="members"
                    hide-default-footer
                    hide-default-header
                    >
                    <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="(item,index) in items" :key="index">
                            <td>{{ item.surname }}, {{ item.first_name }}</td>
                            <td>{{ item.position }}</td>
                            <td>{{ item.civil_status }}</td>
                            <td class="text-right"><v-btn x-small text color="success" @click="redirect(item.id)">View data</v-btn></td>
                        </tr>
                        </tbody>
                    </template>
                    </v-data-table> -->
        </template>
      <va-data-table :show="datatable" @DialogEvent="dtEv"></va-data-table>
    </v-container>
  </div>  
  </template>
  
  <script>
  import {
  mapMutations
} from 'vuex'
  export default {

    data: () => ({
      loading: false,
      duplicate: {},
        dup_action: '',
        datatable: false,
        view: false,
        isImage: false,
        valid: true,
        date_menu:false,
        isMember: false,
        show1: false,
        item: {},
        datalist: [],
        skills: [],
        parent:{},
        regions: [],
        provinces: [],
        municipalities: [],
        barangays:[],
        members: [],
        textRules: [
          v => !!v || 'Field is required'
        ],
        isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      link: '#',
      headers: [
          { text: 'Surname', value:"suranme"},
         // { text: 'First Name', value:"first_name"},
          { text: 'Gender', value:"gender"},
          { text: 'Civil Status', value:"civil_status"},
          { text: '--', value:"--", align: 'right'}
        ]
    }),
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
    },
    created() {
       if (this.$session.exists()) {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
          this.setLoggedIn(true) 
          if(this.$IsNum(this.$route.params.id)) {
            this.ViewData(this.$IsNum(this.$route.params.id))
          } 
          this.setPreset()
          this.item.birth_date = this.$moment("1990-01-01").format('YYYY-MM-DD')
        }
    },
    computed:{
       windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
      headOfFamilyList() {
          var heads = this.datalist.reduce((res, item)=>{
            if(item.position=="Head of the family") {
              res.push(item)
            }
            return res
          }, []);
          return heads
      },
      headOfFamily() {
          var head = this.datalist.reduce((res, item)=>{
            if(item.id==this.item.parent_id) {
              res = item
            }
            return res
          }, {});
          if(head.id) {
            return head.surname + ", " + head.first_name
          }
          return ""
      },
      OCCCUPATIONS(){
        if(this.item.occupation) {
          return this.$store.state.occupations
        } 
        return []
      },
      CATEGORY(){
        var years = this.$moment().diff(this.item.birth_date, 'years');
      
        if(years>14 && years<31) {
            return 'SK'
        } else if (years<14) {
            return 'Children '
        }  else if (years>31 && years<60) {
            return 'Adults'
        }  else if (years>59) {
            return 'Seniors Citizen'
        }  else {
          return ""
        }
      }
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn','setDrawer']),
      memberPos(pos){
        var member = this.members.reduce((res, item)=>{
            if(item.position==pos) {
              res.push(item)
            }
            return res
          }, []);
          return member
      },
      getItemText(item) {
            return item.surname + ', ' + item.first_name;
      },
      getdatalist() {
        this.$http.get("data/list").then(response => {
                    response.data.items != null?this.datalist = response.data.items:this.datalist=[]
                }).catch(e => {
                 console.log(e.data)
             })
      },
      LoadData(){
          //this.redirect(this.duplicate.id)
          this.dup_action = ""
          this.VA_ALERT("info", "Loading page....")
          this.$router.push('/data-sheet/'+this.duplicate.id)
          this.ViewData(this.duplicate.id)
      },
      dtEv(data) {
       
        this.datatable = false
        if(data !== "close") {
          this.item = data
          if(this.isMember==true) {
            this.item.parent_id = this.parent.id
          }
        }
      },
        validate () {
          if (this.$refs.form.validate()) {
            this.loading = true
            !this.item.id?this.NewData():this.UpdateData()
          }
        },
        redirect(id){
          this.VA_ALERT("info", "Loading page....")
          this.$router.push('/data-sheet/'+id)
          setTimeout(()=>{
            this.resetPage()
          },2000)
        },
        resetPage() {
          location.reload();
        },
        setPreset() {
          this.initRegions()
          this.item.region = this.$store.state.address_preset.region
          setTimeout(()=>{
            this.setProvinces()
            this.item.province = this.$store.state.address_preset.province
          }, 500)
          setTimeout(()=>{
            this.setMunicipalities()
            this.item.municipality = this.$store.state.address_preset.municipality
          }, 1000)
          setTimeout(()=>{
            this.setBarangays()
            this.item.barangay = this.$store.state.address_preset.barangay
          }, 1500)
        
        },
        newMember() {
           if(!this.isMember) {
            this.parent=this.item
            this.isMember=true
          }
          this.item={}
          this.setPreset()
         
          if(this.isCameraOpen) {
            this.toggleCamera()
          }
        },
        validateRecord(){
          if(this.$IsNum(this.$route.params.id)) {
            return
          } else {
            this.findRecord()
          }
        },
        findRecord() {
          this.item.first_name = this.$Cap(this.item.first_name)
          this.item.surname = this.$Cap(this.item.surname)
          console.log("dup: ",  this.item)
          this.$http.post("data/get_dup", this.item).then(response => {
           if(response.data.item.id > 0) {
            this.duplicate = response.data.item
            this.dup_action = 'check'
           }
          }).catch(e => {
          console.log(e)
          })
        },
        NewData() {
        
          if(this.isMember==true) {
            this.item.parent_id = this.parent.id
          }
          this.item.category = this.CATEGORY
          this.item.first_name = this.$Cap(this.item.first_name)
          this.item.surname = this.$Cap(this.item.surname)
          this.item.middle_name = this.$Cap(this.item.middle_name)
          this.item.skills = this.skills.join(',')
        this.$http.post("data/new", this.item).then(response => {
            response.data.status? this.VA_ALERT('success', response.data.message): this.VA_ALERT('error', response.data.message)

            if(!response.data.status) {
              this.loading = false
            }
            setTimeout(()=>{
              this.loading = false
              this.$router.push('/census')
            }, 1000)
           // response.data.status?this.item = response.data.item:''
          //   if(this.$IsNum(this.$route.params.id)) {
          //   this.ViewData(this.$IsNum(this.$route.params.id))
          // } 
        }).catch(e => {
        console.log(e)
        })
        },
        ViewData(id) {
          console.log(this.item)
        
        this.$http.post("data/view", {id:id}).then(response => {
            response.data.status?this.item = response.data.item:this.item={}
            this.skills = this.item.skills.split(',')
            response.data.members != null?this.members = response.data.members:this.members=[]
            if(this.item.id) {
              this.getdatalist()
            }
            console.log(response.data)
            this.view = true
        }).catch(e => {
        console.log(e)
        })
        },
        UpdateData() {
        
          this.item.first_name = this.$Cap(this.item.first_name)
          this.item.surname = this.$Cap(this.item.surname)
          this.item.middle_name = this.$Cap(this.item.middle_name)
          this.item.category = this.CATEGORY
          this.item.skills = this.skills.join(',')
          console.log(this.item)
        this.$http.post("data/update", this.item).then(response => {
            response.data.status? this.VA_ALERT('success', response.data.message): this.VA_ALERT('error', response.data.message)
            response.data.status?this.item = response.data.item:''

            if(!response.data.status) {
              this.loading = false
            }
            setTimeout(()=>{
              this.loading = false
              this.$router.back()
            }, 2000)
           
        }).catch(e => {
        console.log(e)
        })
        },
        setProvinces() {
        var obj = this.$store.state.Philippines;
        Object.values(obj).forEach((val) => {
          if (this.item.region == val.region_name) {
            this.provinces = Object.keys(val.province_list);
          }
        });
      },
      setMunicipalities() {
        var obj = this.$store.state.Philippines;
        Object.values(obj).forEach((val) => {
          if (this.item.region == val.region_name) {
            Object.entries(val.province_list).forEach(([key, value]) => {
              if (key == this.item.province) {
                this.municipalities = Object.keys(value.municipality_list);
              }
            });
          }
        });
      },
      setBarangays() {
        var obj = this.$store.state.Philippines;
        Object.values(obj).forEach((val) => {
         
          if (this.item.region == val.region_name) {
            Object.entries(val.province_list).forEach(([province, value]) => {
              console.log(province)
                  Object.entries(value.municipality_list).forEach(([munkey, munvalue]) => {
                      if (munkey == this.item.municipality) {
                          this.barangays = Object.values(munvalue.barangay_list);
                      }
                  })
            });
          }
        });
      },
        initRegions() {
        var items = []
        var entries = Object.entries(this.$store.state.Philippines);
        entries.forEach(([key, value]) => {
          items.push({key:key, name:value.region_name});
        });
        this.regions = items
      },
      emitToParent () {
          this.$emit('DialogEvent', "")
      },
      toggleCamera() {
        this.isImage = false
        if(this.isCameraOpen) {
          this.isCameraOpen = false;
          this.isPhotoTaken = false;
          this.isShotPhoto = false;
          this.stopCameraStream();
        } else {
          this.isCameraOpen = true;
          this.createCameraElement();
        }
      },
      createCameraElement() {
        this.isLoading = true;
        this.item.image = ''
        const constraints = (window.constraints = {
          audio: false,
          video: true
        });


        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(stream => {
            this.isLoading = false;
            this.$refs.camera.srcObject = stream;
          })
          .catch(error => {
            this.isLoading = false;
            alert("May the browser didn't support or there is some errors.", error);
          });
      },
      stopCameraStream() {
          let tracks = this.$refs.camera.srcObject.getTracks();

          tracks.forEach(track => {
            track.stop();
          });
      },
      takePhoto() {
        this.isImage = false
        if(!this.isPhotoTaken) {
          this.isShotPhoto = true;

          const FLASH_TIMEOUT = 50;

          setTimeout(() => {
            this.isShotPhoto = false;
          }, FLASH_TIMEOUT);
        }
        
        this.isPhotoTaken = !this.isPhotoTaken;
        
        const context = this.$refs.canvas.getContext('2d');
        context.drawImage(this.$refs.camera, 0, 0, 320, 237.5);
      },
      saveImage() {
      // const download = document.getElementById("downloadPhoto");
      // const canvas = document.getElementById("photoTaken").toDataURL("image/jpeg")
      // .replace("image/jpeg", "image/octet-stream");
        this.item.image = document.getElementById("photoTaken").toDataURL("image/jpeg")
        console.log( this.item.image)
        this.isImage = true

        //download.setAttribute("href", canvas);
      }
    }
  }
  </script>
  